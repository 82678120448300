@import 'colors';
@import 'variables';

.sidebar {
  position: fixed;
  top: 0;
  width: $sidebar-width;

  display: flex;
  flex: 0 0 $sidebar-width;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 40px 32px 34px;
  overflow: hidden;

  background-color: $bg-4;
  border: $border;

  z-index: 999;

  & > svg {
    width: 106px;
    height: 66px;
    min-height: 66px;
    margin-bottom: 8px;
  }

  &-title {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    color: #aeaeae;

    margin-bottom: 48px;
  }

  &-help {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 10px;
    margin-top: auto;

    border: none;
    outline: none;
    background-color: transparent;

    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: $gray-2;

    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    & > svg {
      width: 24px;
      height: 24px;
      fill: $black;
      margin-right: 16px;
    }
  }
}
