@import 'colors';

$border-radius: 4px;
$sidebar-width: 288px;
$content-width: 936px;
$border: 1px solid $bg-1;

%text_modificator {
  &.bold {
    font-weight: 700;
  }
  &.light {
    font-weight: 300;
  }
}

%text_family {
  font-family: 'Inter', sans-serif;
  color: $black;
}

%auth_form {
  width: 100%;
  max-width: 520px;

  h3 {
    margin-bottom: 16px;
  }

  p:first-of-type {
    margin-bottom: 32px;
  }

  .footnote {
    margin-bottom: 4px;
    color: $gray-3;
  }

  .large {
    width: 100%;
    margin-bottom: 40px;
  }
}

%content_container {
  width: 100%;
  padding: 24px;

  background: #ffffff;
  border-radius: 8px;
}
