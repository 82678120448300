@import 'colors';
@import 'variables';

.button {
  border: none;
  border-radius: $border-radius;
  outline: none;
  background: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: 176px;
  height: 42px;
  padding: 10px 16px;

  font-size: 14px;
  line-height: 21px;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;

  cursor: pointer;

  & > span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;

    &:first-child {
      margin-right: 7px;
    }

    &:last-child {
      margin-left: 7px;
    }
  }

  &.small {
    height: 32px;
    padding: 6px 12px;

    font-size: 13px;
    line-height: 18px;

    svg {
      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  &.large {
    height: 52px;
    padding: 14px 21px;

    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;

    svg {
      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  &.primary {
    color: #ffffff;
    background-color: $gray-1;

    &:hover,
    &:focus {
      background-color: $gray-2;
    }

    &:active,
    &:disabled {
      background-color: $gray-5;
    }
  }

  &.secondary {
    color: $black;
    background-color: $bg-1;

    &:hover,
    &:focus {
      background-color: $bg-2;
    }

    &:active,
    &:disabled {
      background-color: $bg-3;
    }
  }

  &.success {
    color: #ffffff;
    background-color: $success;

    &:hover,
    &:focus {
      background-color: $success-accent;
    }

    &:active,
    &:disabled {
      background-color: $success-light;
    }
  }

  &.error {
    color: #ffffff;
    background-color: $error;

    &:hover,
    &:focus {
      background-color: $error-accent;
    }

    &:active,
    &:disabled {
      background-color: $error-light;
    }
  }

  &.red {
    color: #ffffff;
    background-color: $primary;

    &:hover,
    &:focus {
      background-color: $error-accent;
    }

    &:active,
    &:disabled {
      background-color: $error-light;
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid currentColor;

    &:hover,
    &:focus {
      background-color: $bg-2;
    }

    &:active,
    &:disabled {
      background-color: $bg-1;
      color: $gray-3;
    }

    &.primary {
      color: $gray-1;
    }

    &.secondary {
      color: $gray-3;
      border-color: $gray-2;
    }

    &.success {
      color: $success;
    }

    &.error {
      color: $error;
    }

    &.black {
      color: $gray-1;
      border-color: $gray-1;
    }
  }

  &.flat {
    padding: 0;
    min-width: fit-content;
    height: auto;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
      text-decoration: underline;
    }

    &:active,
    &:disabled {
      color: $gray-3;
      text-decoration: none;
      cursor: not-allowed;
    }

    &.primary {
      color: $black;
    }

    &.secondary {
      color: $gray-3;
    }

    &.success {
      color: $success-accent;
    }

    &.error {
      color: $error-accent;
    }

    &.red {
      color: $primary;
    }

    &.black {
      color: $black;
    }
  }
}
