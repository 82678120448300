@import 'colors';
@import 'variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: rgba($black, 0.7);
  z-index: 9999;
  overflow: auto;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding: 12px 24px 24px;
  max-width: 624px;

  background-color: #ffffff;
  border: none;
  border-radius: $border-radius;

  &:focus {
    outline: none;
  }

  p {
    color: $grey-6;
    margin-bottom: 16px;
  }

  .title {
    margin-bottom: 20px;
  }

  .modal-close {
    position: absolute;
    top: 12px;
    right: 24px;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      fill: $grey-6;
    }
  }
}

.modal-tag {
  .button {
    align-self: center;
    margin-top: 16px;
  }
}

.modal-create {
  .sections {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 112px;
    padding: 16px 56px;

    border-top: 1px solid #f2f2f2;

    .section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        margin-top: 16px;
        min-width: 152px;
      }
    }
  }
}

.modal-confirm {
  .confirm-section {
    border-top: 1px solid #f2f2f2;
    padding-top: 16px;
    margin-bottom: 16px;

    .input-wrapper {
      margin-top: 16px;
      max-width: 240px;
    }
  }

  .buttons-section {
    border-top: 1px solid #f2f2f2;
    padding-top: 24px;
    margin-bottom: 4px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .large {
      text-transform: capitalize;
    }
  }
}

.modal-help {
  .help-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    padding-top: 20px;
    border-top: $border;

    &:not(:last-child) {
      margin-bottom: 36px;
    }

    & > p {
      color: $grey-6;
      max-width: 400px;
    }
  }
}

.modal-preview {
  padding: 0;
  margin: 32px 0 64px;
  max-width: 60vw;

  .modal-close {
    top: 0;
    right: -64px;

    svg {
      fill: #ffffff;
    }
  }
}

.modal-login {
  margin-top: auto;
  max-width: 100vw;
  width: 100vw;
  padding: 36px 24px 60px;
  text-align: center;

  .modal-close {
    display: none;
  }

  button:not(.modal-close) {
    margin-top: 24px;
  }
}
