@import 'colors';
@import 'variables';

.display {
  font-size: 61px;
  line-height: 73px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;
}

h1 {
  font-size: 49px;
  line-height: 59px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;
}

h2 {
  font-size: 39px;
  line-height: 55px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;
}

h3 {
  font-size: 30px;
  line-height: 44px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;
}

.title {
  font-size: 25px;
  line-height: 35px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;
}

.subtitle {
  font-size: 21px;
  line-height: 34px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;
}

.body {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;
}

p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;

  @extend %text_family;
  @extend %text_modificator;

  color: $gray-2;
}

.footnote {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;

  @extend %text_family;
  @extend %text_modificator;

  color: $gray-2;
}
