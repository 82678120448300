@import 'colors';
@import 'variables';

.notification-item {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  padding: 16px 0;
  min-width: 680px;

  &.small {
    gap: 36px;
    padding: 10px 24px;
    min-width: 656px;
    grid-template-columns: 1fr;

    & > .red {
      margin-left: auto;
      min-width: fit-content;
    }
  }

  &:not(:last-child) {
    border-bottom: $border;
  }

  & > .button {
    min-width: 130px;
    max-width: 130px;
  }

  .item-info {
    max-width: 360px;

    p {
      color: $gray-1;
    }

    .footnote {
      color: $gray-3;
      margin-top: 4px;
    }
  }
}
