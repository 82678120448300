@import 'colors';
@import 'variables';

.user-menu {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;

  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: $gray-3;
  text-transform: capitalize;

  border: none;
  outline: none;
  background-color: transparent;

  cursor: pointer;

  &-chevron {
    fill: $gray-3;
    stroke: $gray-3;

    margin-left: 10px;

    transition: transform 250ms ease-in-out;
  }

  &.open {
    .user-menu-chevron {
      transform-origin: center;
      transform: rotateZ(180deg);
    }
  }
}

.menu-body {
  position: absolute;
  top: calc(100% + 8px);
  right: -56px;
  width: 296px;

  background-color: #ffffff;
  border-radius: $border-radius;
  border: $border;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 1000;

  .menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 24px;

    font-family: 'Inter', sans-serif;
    font-size: 20px;
    line-height: 32px;
    color: $gray-1;
    text-decoration: none;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    transition: background-color 250ms ease-in-out;

    &:hover,
    &:focus {
      background-color: rgba($bg-1, 0.3);
    }

    &:not(:last-child) {
      border-bottom: $border;
    }

    & > svg {
      width: 32px;
      height: 32px;
      fill: $black;
      stroke: $black;

      margin-right: 10px;
    }
  }
}
