@import 'colors';
@import 'variables';

.header {
  position: fixed;
  top: 0;
  width: calc(100% - #{$sidebar-width});
  max-height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: $sidebar-width;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 100;

  background-color: $bg-4;
  box-shadow: 0 6px 16px rgba(26, 26, 26, 0.04);

  .search-wrapper {
    width: 100%;
    max-width: 392px;
  }

  .dropdowns-wrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 24px;
  }
}
