@import 'variables';

.content {
  margin-top: 70px;
  margin-left: $sidebar-width;
  padding-top: 80px;
  padding-bottom: 80px;
  min-width: 1152px;

  & > * {
    width: 100%;
    max-width: $content-width;
    margin: 0 auto;
  }
}
