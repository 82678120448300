@import 'colors';
@import 'variables';

.notifications-menu {
  position: relative;
  width: 32px;
  height: 32px;

  border: none;
  outline: none;
  background-color: transparent;

  cursor: pointer;

  & > svg {
    width: 32px;
    height: 32px;
    stroke: $black;
    fill: none;
  }

  .has-unread {
    position: absolute;
    top: 6px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-radius: $border-radius;
    background-color: $primary;
  }
}

.notifications-menu-body {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 675px;

  background-color: #ffffff;
  border-radius: $border-radius;
  border: $border;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 1500;

  & > ul {
    max-height: 430px;
    overflow-y: scroll;
  }

  .menu-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 24px 32px;
    border-top: $border;

    .button {
      min-width: fit-content;
    }
  }
}
