@import 'colors';

.app-wrapper {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: $bg-3;

  &.login {
    background-color: $bg-1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: auto;
  }

  .sort {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 4px;

    & > button {
      display: none;
    }
  }

  .password-success {
    padding: 80px 48px;
    text-align: center;

    .button {
      margin-top: 12px;
    }
  }
}

.no-scroll {
  overflow: hidden;
}
