$primary: #d22026;
$secondary: #20d2cc;

//background
$bg-1: #ebebeb;
$bg-2: #f2f2f2;
$bg-3: #f7f7f7;
$bg-4: #fafafa;

//greyscale
$black: #1a1a1a;
$gray-1: #2b2b2b;
$gray-2: #4d4d4d;
$gray-3: #6d6d6d;
$gray-4: #828282;
$gray-5: #acacac;
$gray-6: #cacaca;
$grey-6: #293854;

//utility
$error: #df3030;
$error-light: #f1a7a7;
$error-accent: #b11b1b;
$success: #0bc196;
$success-light: #9ffae4;
$success-accent: #089171;
$warning: #f2a818;
$warning-light: #fadb9e;
$warning-accent: #b57803;

$red: #b70011;
