@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Rubik:wght@300;400;700&family=Source+Sans+Pro:wght@300;400;700&display=swap');

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    width: 100%;
    padding: 0 108px;
    margin: 0 auto;
}

.with-tabs {
    display: flex;
}
