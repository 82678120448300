@import 'colors';
@import 'variables';

.sidebar-nav {
  width: 100%;
}

.sidebar-menu {
  display: grid;
  gap: 20px;

  .sidebar-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 16px 10px;

    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: $gray-2;
    text-decoration: none;

    background-color: transparent;
    border-radius: $border-radius;

    transition: background-color 150ms ease-in-out;

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      fill: $black;
    }

    &:hover,
    &:focus,
    &.active {
      background-color: $primary;
      color: #ffffff;

      .sidebar-link-icon {
        fill: #ffffff;
      }
    }

    &:active {
      background-color: $error-light;
      color: #ffffff;

      .sidebar-link-icon {
        fill: #ffffff;
      }
    }
  }
}
